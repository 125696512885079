@use './variables.module.scss' as *;
.is-5 {
  font-size: 1.25rem;
}

.title:not(:last-child) {
  margin-bottom: 2rem;
}

.title {
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
  word-break: break-word;
}

.subtitle {
  word-break: break-word;
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}

// table container

.acmTableContainer {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  margin-top: -1rem;
}

// Table info header.
.acmTableInfoHeader {
  flex: 0 0 6rem;
  border-bottom: 1px solid #bcccdc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Table header text
.acmTableHeaderText {
  font: normal normal bold 1.4rem/1.9rem var(--font-family-primary);
  letter-spacing: 0px;
  color: $gray-4;
}

.actionText {
  font: normal normal normal 1.2rem/1.6rem var(--font-family-primary);
  letter-spacing: 0px;
  color: var(--primary-accent-color);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

:global .isNew {
  &:after {
    content: 'New';
    padding: 0.6rem 0.7rem;
    margin: 0 1.2rem;
    background: $new-massage-tag-color;
    border-radius: 500px;
    line-height: 1;
    color: white;
    text-transform: capitalize;
    font-size: 9px;
  }
}

@media screen and (min-width: 1280px) {
  :global .chart-label {
    font-size: 12px;
  }

  :global .comment-tile-text {
    .comment-number {
      font-size: 14px;
    }

    .comment-label {
      font-size: 14px;
    }
  }
}

:global .dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #222;
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (min-width: 1400px) {
  :global .chart-label {
    font-size: 14px;
  }

  :global .comment-tile-text {
    .comment-number {
      font-size: 16px;
    }

    .comment-label {
      font-size: 16px;
    }
  }
}

:global .isBeta {
  &:after {
    position: absolute;

    font-family: var(--font-family-primary);
    font-style: italic;
    font-weight: bold;
    font-size: 10px;
    /* identical to box height */
    content: 'BETA';
    padding: 0.2rem 0.6rem;
    color: #fcb720;
    top: 0.2rem;
  }
}

:global .isAlpha {
  &:after {
    position: absolute;

    font-family: var(--font-family-primary);
    font-style: italic;
    font-weight: bold;
    font-size: 10px;
    /* identical to box height */
    content: 'ALPHA';
    padding: 0.2rem 0.6rem;
    color: #fcb720;
    top: 0.2rem;
  }
}

:global {
  .amplifyChat .galleryItem:nth-last-child(1),
  .amplifyChat .gallery .galleryItem {
    border: none;
    img {
      height: 100%;
      object-fit: fill;
    }
    min-width: 195px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 5px 1px #403c433d;
    border: 0;

    flex: 1;
    & + .galleryItem {
      margin-left: 5px;
      width: 190px;
      min-width: 190px;
    }
    p.galleryItemTitle {
      flex: auto;
    }
  }
  .amp-lite {
    .amplifyChat {
      .ChatLog__message {
        & > .quickTextButtonBasic:last-child {
          .quickTextButtonRounded {
            border-bottom: 0;
          }
        }
      }
      .galleryItemImageSquare,
      .galleryItemImageHorizontal {
        width: 100%;
        object-fit: contain;
        height: unset;
      }
      .ChatLog__entry {
        .reactionInfoContent {
          font-weight: 400;
          text-align: start;
          padding: 2px;
          font-family: inherit;
        }
        &.with-emoji-container {
          margin-bottom: unset;
        }
        &.ChatLog__entry_mine {
          .gallery + .attachmentText {
            border-top: 1px solid #929db9;
          }
          .attachmentText {
            padding: 0.2rem 0.5rem;
            background: #b6c3e6;
            max-width: min-content;
            min-width: 100%;
          }
          .galleryArrowRight,
          .galleryArrowLeft {
            background: #929db9ba;
            border: 1px solid #929db9;
          }
        }
        .galleryArrowRight,
        .galleryArrowLeft {
          background: #18b3fcd1;
          border: 1px solid #18b3fc;
        }
      }
      &.galleryArrowRight,
      &.galleryArrowLeft {
        cursor: pointer;
      }
      padding: 0;
      &.ChatLog__entry {
        list-style-type: none;
        // overflow: hidden;

        .quickTextButtonRounded {
          border: 0;
          border-radius: 0;
          border-top: 1px solid #19b4fc;
          color: #19b4fc;
        }
        .quickTextButtonText {
          padding: 0.5rem 0;
        }
        .quickTextButtonBasic {
          width: 100%;
          height: auto;
        }
        p.amplifyChat {
          padding: 1rem;
        }
        .ChatLog__message {
          background: none;
          padding: 0;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          font-weight: unset;
          color: unset;
          border-top: none;
          border-bottom: none;
          margin: 0;
          max-width: 100% !important;
          &:before {
            display: none;
          }
          .gallery.horizontal {
            // overflow: hidden;
          }
        }
        &.ChatLog__entry_mine {
          p.amplifyChat {
            padding: 1rem;
          }
          .ChatLog__message {
            background: none;
            padding: 0;
            border-radius: 0;
            font-weight: unset;
            color: unset;
            border-top: none;
            border-bottom: none;
            &:before {
              display: none;
            }
          }
        }
      }
      embed {
        object-fit: contain;
      }
      object {
        display: flex;
        body {
          display: flex;
        }
        video {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .amplifyChat .gallery .galleryItem,
    .galleryItem.attachment {
      border: none;
      img {
        height: auto;
        object-fit: fill;
        width: 100%;
      }
      min-width: 195px;
      display: flex;
      flex-direction: column;
      box-shadow: 0 2px 5px 1px #403c433d;
      border: 0;

      flex: 1;
      & + .galleryItem {
        margin-left: 5px;
      }
      p.galleryItemTitle {
        flex: auto;
      }
    }
  }
}
