.errorModalContainer {
  font-family: var(--font-family-primary);
  border-radius: 5px;
  :global .ant-modal-content {
    border: none;
    border-radius: 5px;
  }
  :global .ant-modal-body {
    padding: 1.5rem 2rem;
  }
  :global .ant-modal-title {
    font-size: 2.72rem;
    color: #363636;
    font-weight: 600;
    line-height: 1.125;
  }
  :global .ant-modal-header {
    padding: 2rem;
    padding-bottom: 1.5rem;
    border-radius: 5px;
  }
  :global .ant-modal-header,
  :global .ant-modal-footer {
    border: none;
    text-align: left;
  }
  :global .ant-modal-footer {
    padding: 0.5rem 2rem 1.5rem;
  }
}
.errorModalButton {
  font-size: 1.4rem;
  border-radius: 5px;
  font-weight: 700;
  height: 2.25em;
  line-height: 1.5;
}
.errorModalReportButton {
  color: '#eb9c6c';
  cursor: pointer;
}
