@use '../../../../common/scss/variables.module.scss' as *;
.pageSelectWrapper {
  &:not(:global(.is-hidden-desktop)) {
    width: 30rem;
  }
  :global {
    .ant-select {
      width: 100%;
      .ant-select-selector {
        background: transparent;
        color: $white;
        border: 1px solid $fb-page-selector-border;
        border-radius: 6px;
        .ant-select-selection-item {
          a {
            color: white;
            .platformIcon {
              max-height: 22px !important;
              max-width: 22px !important;
              height: 22px !important;
              width: 22px !important;
              margin-left: auto;
              border: 1px solid white;
            }
          }
          .ant-avatar {
            margin-right: 0.5rem;
            margin-bottom: 2px;
            width: 18px;
            height: 18px;
            line-height: 18px;
            border-radius: 50%;
            border: 1px solid white;
          }
          .ant-avatar-group {
            .ant-avatar-icon:nth-child(2),
            .ant-avatar-circle:nth-child(2) {
              width: 12px;
              height: 12px;
              margin-top: 3px;
              margin-left: -10px;
              border: none;
              background-color: white;
            }
          }
        }
        .ant-select-selection-item :nth-child(1) div:nth-child(2) {
          padding-top: 0;
        }
      }
    }
  }
}
:global {
  .ant-select-dropdown {
    .ant-select-item {
      .ant-select-item-option-content {
        .ant-avatar-group {
          .selected-platform-icon {
            max-width: 22px;
            max-height: 22px;
            width: 22px;
            height: 22px;
          }
          .ant-avatar-icon {
            border: none;
          }
          .ant-avatar-circle:nth-child(2),
          .ant-avatar-icon:nth-child(2) {
            width: 18px;
            height: 18px;
            margin-top: 11px;
            margin-left: -18px;
          }
        }
      }
    }
  }
}

:global {
  .accountSelector {
    .ant-select {
      .ant-select-selector {
        span.ant-select-selection-item:before {
          background-image: url('/assets/svg/account-select.svg');
          background-size: 10px 20px;
          display: inline-block;
          width: 30px;
          height: 18px;
          content: '';
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          top: 0.5rem;
          margin-right: 6px;
        }
      }
    }
  }
}

:global .page-select-dropdown {
  .ant-avatar {
    margin: 0 3px 5px 5px;
  }
  .ant-select-item {
    padding: 1rem 1.2rem;
  }
  .ant-select-item-group {
    background: #e6f4fb;
    margin: 0.5rem 0;
    &:first-child {
      margin: 0 0 0.5rem 0;
    }
  }
  .ant-select-item-option-grouped {
    padding-left: 12px;
    border-bottom: 1px solid #cccccc1a;
  }
  &.ant-select-dropdown {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    border: 1px solid rgb(186, 231, 255);
    border-radius: 6px;
    padding-top: 0;
  }
}
.label {
  display: inline-block;
  padding: -20px 0 0 0;
}
.platformIconOverlay {
  width: '15px';
  height: '15px';
  margin-top: '11px';
  margin-bottom: '-18px';
}
.avatarGroup {
  width: 100%;
  // margin-top: 5px;
  // margin-bottom: -10px;
  .platformIcon {
    border-radius: 5px;
    border: 1px solid white;
    background-color: white;
    max-height: 22px !important;
    max-width: 22px !important;
    height: 22px !important;
    width: 22px !important;
  }
  a {
    color: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      cursor: default;
    }
  }
  .profileIconTextContainer {
    display: flex;
    align-items: center;
  }
}
.labelContainer {
  // padding-top: 5px;
  display: flex;
  align-items: center;
  .label {
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
// .labelContainerPlatform {
// padding-top: 7px;
// }
.optionsFormatter {
  display: inline-flex;
  width: 100%;
}
