.copyButton {
  display: inline-flex;
  position: relative;
  margin: 0;

  span {
    display: flex;
    cursor: pointer;
  }
  .copiedTooltipFloating {
    position: absolute;
    color: #686464;
    -moz-transition: opacity 0.6s;
    -webkit-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
    padding: 3px 7px;
    border-radius: 7px;
    box-shadow: 1px 1px 5px rgba($color: #000000, $alpha: 0.2);
  }
  .copiedTooltipFloating.active {
    opacity: 1;
    left: -1.7rem;
    z-index: 1;
  }
  .copiedTooltipFloating {
    background: white;
  }
  // @media screen and (max-width: 600px) {
  //   .copiedTooltipFloating.active {
  //     opacity: 1;
  //     left: unset;
  //     z-index: 1;
  //     right: 0;
  //   }
  // }
}

@media only screen and (max-width: 600px) {
  .copiedTooltipFloating.active {
    top: 50% !important;
    transform: translateY(-55%);
    left: auto !important;
    right: calc(100% + 10px);
  }
}
