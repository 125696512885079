.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .imageMask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
    .imageDownloadMaskInfo {
      color: white;
    }
  }
  .imageMask:hover {
    opacity: 1;
  }
}
