.attachmentContainer {
  padding: 5px;
  border: 1px solid #ccc;
  height: 80px;
  position: relative;
  img {
    height: 80%;
    width: 100%;
    object-fit: cover;
  }
  .overlay:hover {
    opacity: 1;
  }
  .iconSpace {
    width: 68px;
    height: 58px;
    background: #ffffff;
    border-radius: 6px;
    display: inline;
  }
  .iconStyle {
    justify-content: center;
    text-align: center;
    padding: 15px;
  }
  .fileNameSpace {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    text-overflow: ellipsis;
  }
  .fileName {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 19px;
    color: #444444;
    width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }
  .fileNameNew {
    padding-left: 5px;
    width: 100px;
    @extend .fileName;
  }

  .fileSize {
    // padding-left: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 19px;
    color: #444444;
    width: 80px;
  }
  .overlay {
    position: absolute;
    top: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
    transition: 0.5s ease;
    opacity: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imageFileNameSpace {
    word-break: break-word;
  }

  .attachmentFooter {
    display: flex;
    align-items: center;
    .fileName {
      font-size: 1.1rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    .closeIcon {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #f5f5f5;
      box-shadow: 1px 2px 5px rgba($color: #000000, $alpha: 0.3);
      border-radius: 50%;
      padding: 3px;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
