.spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100000;
    left: 0;
    top: 0;
    background-color: rgba(192, 192, 192, 0.5);
  }
}
