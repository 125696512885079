.vertical-line {
  display: inline-block;
  border-left: 0.1rem solid #ccc;
  margin: 0 1rem;
  height: 100%;
}

.align-item-centered {
  display: flex;
  align-items: center;
  justify-items: center;
}

.disable-actions {
  pointer-events: none !important;
  opacity: 0.4 !important;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.m-auto {
  margin: auto;
}
.mr-auto {
  margin-right: auto;
}
.is-word-break {
  word-break: break-word;
}

// .ql-snow.ql-toolbar button,
// .ql-snow .ql-toolbar button {
//   padding: 0 !important;
//   color: black;
// }
