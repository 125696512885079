.warningMessage {
  display: flex;
  justify-content: center;
  font-style: 'normal';
  font-weight: 300;
  font-size: '14px';
  line-height: '19px';
  color: '#000000';
}
.accountName {
  align-items: center;
  display: flex;
  justify-content: center;
  font-style: 'normal';
  font-weight: 700;
  font-size: '12px';
  line-height: '19px';
  color: '#000000';
}
.confirmModalDetails .broadcastDetailRow {
  justify-content: start;
  align-items: center;
  padding: 10px;

  .detail-label {
    color: #999999;
    font-size: 12px;
  }
  .detail-value {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    .detail-value-seperator {
      margin: 0 5px;
    }
  }
  .detail-value.broadcast-type-value {
    font-size: 15px;
  }
  .detail-value.broadcast-name-value {
    color: #1ecfffb3;
  }

  .left-audit-detail {
    padding-right: 2rem;
  }
  .right-audit-detail {
    padding-left: 2rem;
  }
  .mediaImgIcon {
    width: auto !important;
    height: auto !important;
    max-width: 300px;
    max-height: 15rem;
  }
}
.complexTargetInfo {
  color: #ff595e;
  font-style: italic;
  margin: 1rem 0;
  .infoIcon {
    height: 12px;
    width: 12px;
  }
}

.customModalHeader {
  font-weight: bold;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .lineDivider {
    width: 30%;
    min-width: 30%;
  }

  .modalSubHeader {
    font-size: 12px;
    color: #444444;
    font-weight: normal;
  }
}
.msgPreviewContainer {
  border-color: #e2e2e2;
  resize: vertical; // Allows vertical resizing
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: #000;
  padding: 8px;
  border-radius: 4px;
  overflow-y: auto;
  outline: none;
}
