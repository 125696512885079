.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 100001;
  background-color: #fff;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo {
  height: 30px;
  margin-bottom: 10px;
}
.title {
  font-size: 18px;
}
.subtitle {
  font-size: 12px;
}

@media (orientation: landscape) {
  .container {
    display: flex;
  }
}
