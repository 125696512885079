@use '../../../common/scss/variables.module.scss' as *;

.recommendationContainer {
  display: flex;
  cursor: pointer;
}

.recommendation {
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-size: 1rem;
  justify-content: center;
  padding-bottom: calc(0.5rem - 1px);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: calc(0.5rem - 1px);
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  position: relative;
  min-height: 35px;
  cursor: pointer;
}
.recommendationText {
  margin: auto;
  font: normal normal normal 1.4rem/1.9rem var(--font-family-primary);
  padding: 0 4rem;
}
.isDefaultRecommendation {
  composes: recommendation;
  border-color: #aaa;
  color: #aaa;
}
.isBlockRecommendation {
  composes: recommendation;
  border-color: #ff7c7c;
  color: #ff7c7c;
}
.isHideRecommendation {
  composes: recommendation;
  border-color: #1297e0;
  color: #1297e0;
}
.isLikeRecommendation {
  composes: recommendation;
  border-color: #5ba25b;
  color: #5ba25b;
}
.isPublicReplyRecommendation {
  composes: recommendation;
  border-color: #9078c7;
  color: #9078c7;
}
.isReviewRecommendation {
  composes: recommendation;
  border-color: #1b3b74;
  color: #1b3b74;
}
.isTag {
  composes: recommendation;
  border-color: #aaa;
  color: #666;
  cursor: pointer;
}
.recIconContainer {
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  margin-right: 1rem;
  position: absolute;
  left: 5px;
}
.recIconBlockContainer {
  composes: recIconContainer;
  background-color: #ff7c7c;
}
.recIconHideContainer {
  composes: recIconContainer;
  background-color: #1297e0;
}
.recIconLikeContainer {
  composes: recIconContainer;
  background-color: #5ba25b;
}
.recIconPublicReplyContainer {
  composes: recIconContainer;
  background-color: #9078c7;
}
.recIconReviewContainer {
  composes: recIconContainer;
  background-color: #1b3b74;
}
.iconPositionStatic {
  position: static;
  left: unset;
  flex: 0 0 25px;
}
.fixTagWidth {
  width: 24.5rem;
}
