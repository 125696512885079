// :global {
.trillerContainer {
  .button {
    &.is-primary {
      background-color: var(--primary-accent-color);
      &:hover {
        background-color: var(--primary-accent-color-light);
      }
      &.is-outlined,.ant-btn{
        border-color: var(--primary-accent-color);
        color: var(--primary-accent-color);
        &:hover, &:focus{
          background-color: var(--primary-accent-color-light);
              border-color: var(--primary-accent-color-light);
        }
      }
    }
  }

  .box {
    .card-header {
      .ant-select-single {
        .ant-select-selector {
          .ant-select-selection-item {
            color: var(--primary-accent-color);
          }
        }
      }
    }
  }
}
// }
