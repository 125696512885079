// src/components/header.scss
.header {
  display: flex;
  align-items: center; // Align items vertically
  background-color: transparent; // Header background color
  justify-content: space-between;
  width: 100%;

  .logo-container {
    display: flex;
    align-items: center; // Align logo and title
    .logo-link {
      display: flex;
    }
  }

  .logo {
    height: 40px; // Adjust the height as needed
    margin-right: 15px; // Space between logo and title
  }

  .app-title {
    color: white;
    margin: 0;
    font-size: 24px; // Adjust font size as needed
  }
  .headerAppLinks {
    justify-items: center;
    align-items: center;
    display: flex;
    img {
      height: 2rem;
    }

    a {
      display: flex;
      + a {
        margin-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    // justify-content: center;
    .headerAppLinks,
    .appLinks {
      display: none;
    }
  }
}

.appLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  justify-items: center;
  align-items: center;
  margin: auto;
  img {
    height: 2rem;
  }

  a {
    display: flex;
    + a {
      margin-left: 5px;
    }
  }
}

@media (min-width: 768px) {
  .appLinks {
    display: none;
  }
}
