// Default theme
$primary-font: Nunito Sans, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

:root {
  --primary-theme-color: #0e204e;
  --primary-accent-color: #1297e0;
  --off-black-color: #646464;
  --left-nav-Active-tab: #445788;
  --left-navigation-collapsible-bg: #101d41;
  --primary-accent-color-light: #8dc5e3;
  --font-family-primary: #{$primary-font};
  --real100vh: 100vh;
}

$primary-theme-color: #0e204e !default;
$primary-accent-color: #1297e0 !default;
$primary-accent-color-light: #8dc5e3;
$primary-text-color: #a7d0e5;
$primary-hover-color: #b4e4ff;
$alt-text-color: #000;
$invalid-input-border: #f14668;
$white: white;
$gray-50: #fafafa;
$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$gray-2: #222;
$gray-4: #444;
$gray-6: #666;
$gray-8: #888;
$gray-9: #999;
$gray-a: #aaa;
$gray-d6: #d6d6d6;
$gray-eb: #ebebeb;
$gray-e: #eee;
$gray-e3: #e3e3e3;
$gray-ef: #efefef;
$gray-f2: #f2f2f2;
$gray-f5: #f5f5f5;
$gray-f8: #f8f8f8;
$gray-f9: #f9f9f9;
$input-focus-border-color: #d6f1ff;
$gray-c3: #c3c3c3;
$gray-fc: #fcfcfc;
$tomato-red: #ff595e;
$warning-yellow: #fcb720;
$sucess-green: #79c32f;
$gray-d: #ddd;
$table-header-color: #627d98;
$sub-section-bg-color: #fafbff;

//comment keyword colors
$intent: #caeafc66;
$entity: #829f3326;
$mentions: #ffddd7;
$replies: #ffdeee;
$comments: #dfdeff;
$sentiment-negative: #ff9d9d33;
$sentiment-neutral: #9090904c;
$sentiment-positive: #34cbb433;
$recommendation-hide: #1297e033;
$recommendation-block: #ff7c7c33;
$recommendation-like: #5ba25b33;
$recommendation-publicreply: #9078c733;
$paid-post-type: #1297e066;
$organic-post-type: #eecb5799;
$recommendation-review: #1b3b7433;

//message
$messageStatus: red;

// dashboard

$leftRightPaddingForComponents: 5px;

$intentPercentageBG: $primary-accent-color;
$welcomeTileColor: $primary-theme-color;
$borderRadius: 4px;
$darkBlueTextColor: #00304a;
$primary-accent-color-BG: #e6f4fb;
$info-danger-red: #f52d94;
$info-danger-bg: #f62d9417;
$fb-page-selector-border: #738ccc;
$left-nav-Active-tab: #445788 !default;

//dashboard placeholder

$gray-d0: #d0d0d0;

// sidebar collapsible
$left-navigation-collapsible-bg: #101d41;
//comment tag colors
$public-reply-tag-color: #9078c7;

//inbox
$new-message-bg: #fffbf2;
$new-massage-tag-color: #fcb720;

//For using in JS
:export {
  primaryThemeColor: $primary-theme-color;
  primaryAccentColor: $primary-accent-color;
  primaryFont: $primary-font;
  grayF2: $gray-f2;
  intent: $intent;
  entity: $entity;
  mentions: $mentions;
  replies: $replies;
  comments: $comments;
  sentimentNegative: $sentiment-negative;
  sentimentNeutral: $sentiment-neutral;
  sentimentPositive: $sentiment-positive;
  sentimentMixed: $organic-post-type;
  recommendationDefault: #aaaaaa80;
  recommendationLike: $recommendation-like;
  recommendationHide: $recommendation-hide;
  recommendationBlock: $recommendation-block;
  recommendationPublicReply: $recommendation-publicreply;
  recommendationReview: $recommendation-review;
  paidPostType: $paid-post-type;
  organicPostType: $organic-post-type;
  blockDeleteTagColor: #ff7c7c;
  hideTagColor: #1297e0;
  likeTagColor: #5ba25b;
  publicReplyTagColor: $public-reply-tag-color;
  primaryHoverColor: $primary-hover-color;
  messageStatus: #fbedcd;
  sessionStartType: #c5d5fe;
}

//header

$amp-navbar-color: $primary-theme-color;

$box-shadow: 0px 0px 4px #00000029;

// Triller override theme
.trillerContainer {
  --primary-theme-color: #14172e;
  --primary-accent-color: #687486;
  --left-nav-Active-tab: #fe0d8a;
  --left-navigation-collapsible-bg: #14172e;
  --primary-accent-color-light: #b3b9c4;
  --font-family-primary: #{$primary-font};
}
// Triller override theme
.cliqzContainer {
  --primary-theme-color: #001733;
  --primary-accent-color: #1ecfff;
  --left-nav-Active-tab: #1ecfffb3;
  --left-navigation-collapsible-bg: #001733;
  --primary-accent-color-light: #1eceff71;
  --font-family-primary: Verdana, Geneva, sans-serif;
}

.juliusContainer {
  --header-fonts-color: #705dfb;
  --primary-theme-color: #07102e;
  --primary-accent-color: #55bada;
  --left-nav-Active-tab: #705dfb;
  --left-navigation-collapsible-bg: #0d193f;
  --primary-accent-color-light: #55bada99;
  --font-family-primary: 'Open Sans', sans-serif;
}

$commentViewerHeight: calc(100vh - 190px - 7.5rem);

$commentViewerHeightAfterSelection: calc(#{$commentViewerHeight} - 9.5rem);
