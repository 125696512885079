@use '../../../common/scss/variables.module.scss' as *;

.normal {
  border-radius: 4px;
  background: #445788 0% 0% no-repeat padding-box !important;
  display: flex;
  align-items: center;
  z-index: 100001;

  :global(.ant-notification-notice-message),
  :global(.ant-notification-notice-description) {
    color: white;
    font: normal normal normal 1.4rem/1.9rem var(--font-family-primary);
    letter-spacing: 0px;
    margin-bottom: 0;
  }
  :global(.ant-notification-notice-description) {
    margin-top: 7px;
  }
  :global(.ant-notification-notice-close) {
    line-height: 0;
    position: static;
    margin-left: auto;
  }

  &:global(.ant-notification-notice) {
    margin-left: calc(190px - 1.5rem) !important;
  }
}
.error {
  composes: normal;
  background: $tomato-red 0% 0% no-repeat padding-box !important;
}
.warning {
  composes: normal;
  background: $warning-yellow 0% 0% no-repeat padding-box !important;
}
.success {
  composes: normal;
  background: $sucess-green 0% 0% no-repeat padding-box !important;
}

:global(.ant-notification) {
  z-index: 100001;
}

@media screen and (max-width: 600px) {
  :global {
    .ant-notification {
      margin-left: 0 !important;
      right: 0 !important;
    }
    .ant-notification .ant-notification-notice {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}
