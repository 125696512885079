@use '../../../../common/scss/variables.module.scss' as *;

.socialMediaAccountsContainer {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  :global {
    .ant-table {
      height: calc(100vh - 70px - 15rem);
      overflow: auto;
    }
  }
}
.botActionsPopover {
  .botContainer {
    .name {
      color: $gray-6;
      font-size: 12px;
      font-weight: bold;
    }
    .value {
      color: $gray-6;
      font-size: 12px;
    }
  }
  :global(.ant-popover-inner-content) {
    padding: 15px;
  }
  :global(.ant-popover-inner) {
    min-width: 100px;
    width: 500px;
  }
}
.infoHeader {
  flex: 0 0 6rem;
  border-bottom: 1px solid #bcccdc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -1rem;
}
.connectedText {
  composes: acmTableHeaderText from '../../../../common/scss/common.scss';
}
.connectAccount {
  display: flex;
  align-items: center;
}
.connectFbAccount {
  display: flex;
  align-items: center;
  cursor: pointer;
  > span {
    color: $gray-6;
    font: normal normal normal 1.2rem/1.6rem var(--font-family-primary);
  }
  margin-right: 2.5rem;
}
.connectInstaAccount {
  composes: connectFbAccount;
  margin-right: 0;
  > img {
    margin-right: 0.5rem;
    height: 1.5rem;
    width: auto;
  }
}
.fbLogo {
  height: 15px;
  > path {
    fill: var(--primary-accent-color);
  }
}
.connectContainer {
  display: flex;
  height: 100%;
  justify-content: center;
}
.nameCell {
  > img {
    border-radius: 50%;
    height: 2.5rem;
    width: auto;
    cursor: pointer;
  }

  .icon {
    cursor: pointer;
    & + span {
      margin-left: 1rem;
    }
  }
  flex-direction: row;
  display: flex;
  align-items: center;
}
.infoCell {
  margin-left: 10px;
  flex-direction: column;
  display: flex;
}
.platformBotIdCell {
  align-items: center;
  flex-direction: row;
  display: flex;
  > span {
    color: #aaa;
    font-size: 12px;
    margin-top: 2px;
    margin-right: 5px;
  }
}

.platformListContainer {
  display: flex;
  height: 100%;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .infoHeader {
    margin-top: -0.2rem;
  }
  .connectedText {
    color: #666666;
    font-size: 17px;
    font-weight: 400;
  }
}

.permissionViewerModal {
  :global {
    .ant-modal-body {
      padding: 0px 24px;
    }
  }
  .permissionViewerBody {
    max-height: 70vh;
    overflow: auto;
    .permissionsList {
      list-style-type: disc;
      list-style-position: inside;
      li {
        margin: 10px 5px;
      }
    }
  }
}
