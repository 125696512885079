@use '../../../common/scss/variables.module.scss' as *;

.ampLiteButton {
  font-size: 1.4rem;

  &:global(.is-medium) {
    font-size: 1.4rem;
  }
}

.btnContainer {
  display: inline-flex;
  a {
    font-size: 1.3rem;
    margin-bottom: 0;
    color: var(--primary-accent-color);
    margin: 0.25rem 0;
    display: inline-flex;
    align-items: baseline;
    &:global(.error) {
      color: $tomato-red;
    }
  }
  & > * {
    display: inline-block;
  }
  svg {
    margin-right: 0.3rem;
  }
}

.deleteConfirmContainer {
  display: flex;
  position: relative;
}
