.cliqzContainer {
  font-family: Verdana, Geneva, sans-serif;
  .leftNavigationBarBgCliqz {
    color: var(--primary-theme-color);
  }
  a {
    color: var(--primary-accent-color);
  }
  .button.is-primary {
    background-color: var(--primary-accent-color);
    &:hover {
      background-color: var(--primary-theme-color);
    }
  }
  //login

  [class*='lite-points_li'] {
    path {
      fill: var(--primary-accent-color);
    }
  }
  &[class*='login_halfSection'] {
    background: white;
  }
  &[class*='lite-points_container'] {
    background: white;
    [class*='lite-points_ul'] {
      display: none;
    }
    [class*='lite-points_patternContainer'] {
      display: none;
    }
    [class*='lite-points_logo'] {
      max-width: 350px;
      width: auto;
    }

    [class*='lite-points_headerM'] {
      background: white;
    }
    @media screen and (max-width: 600px) {
      [class*='lite-points_logo'] {
        width: 280px;
        margin: auto;
        display: flex;
      }
      [class*='lite-points_headerM'] {
        height: 110px;
      }
    }
  }
  [class*='lite-points_patternContainer'] {
    display: none;
  }

  &.amp-lite {
    font-family: Verdana, Geneva, sans-serif;
    //charts
    [class*='labelClass'] {
      font-family: var(--font-family-primary);
    }
    [class*='labelContainerClass'] {
      @media screen and (min-width: 1280px) {
        .labelValueClassName {
          font-size: 12px;
        }
        [class*='labelClass'] {
          font-size: 12px;
        }
      }
      @media screen and (min-width: 1400px) {
        .labelValueClassName {
          font-size: 16px;
        }
        [class*='labelClass'] {
          font-size: 13px;
        }
      }
    }

    @media screen and (min-width: 1280px) {
      .chart-label {
        font-family: Verdana, Geneva, sans-serif;
      }

      .comment-tile-text {
        .comment-number {
          font-size: 12px;
        }

        .comment-label {
          font-size: 13px;
          font-family: Verdana, Geneva, sans-serif;
        }
      }
    }

    @media screen and (min-width: 1400px) {
      .chart-label {
        font-family: Verdana, Geneva, sans-serif;
      }

      .comment-tile-text {
        .comment-number {
          font-size: 14px;
        }

        .comment-label {
          font-size: 14px;
          font-family: Verdana, Geneva, sans-serif;
        }
      }
    }

    .comment-number {
      font-family: var(--font-family-primary);
    }
    .comment-tile-text .comment-label {
      // font-family: var(--font-family-primary);
    }
    //color icons
    [class*='button_btnContainer'] a {
      path {
        fill: currentColor;
        stroke: currentColor;
      }
    }

    .navbar-item {
      [class*='Header_help'] {
        border: 1px solid #00c8fd80;
        & > a {
          color: var(--primary-accent-color);
        }
      }
      .ant-select .ant-select-selector {
        border: 1px solid #00c8fd80;
      }
      [class*='Header_navNotification'] {
        path {
          fill: #1ecfff;
        }
      }
      [class*='user-menu_userPhoto'] {
        border: 2px solid #1ecfff;
      }
    }
    .ant-tabs {
      .ant-tabs-tab-btn {
        font-family: Verdana, Geneva, sans-serif;
      }
      .ant-tabs-ink-bar {
        background-color: var(--primary-accent-color);
      }
      .ant-tabs-tab {
        &.ant-tabs-tab-active .ant-tabs-tab-btn {
          font-family: Verdana, Geneva, sans-serif;
          color: var(--primary-accent-color);
        }
      }
    }
    [class*='page-selector_pageSelectWrapper'] {
      .ant-select .ant-select-selector span.ant-select-selection-item {
        color: var(--primary-accent-color);
      }
      .ant-select .ant-select-selector .ant-select-selection-item .ant-avatar {
        border: 1px solid var(--primary-accent-color);
      }
    }

    .accountSelector .ant-select .ant-select-selector span.ant-select-selection-item {
      color: var(--primary-accent-color);
      &:before {
        background-image: url('/assets/svg/acc-select-blue.svg');
      }
    }
    [class*='intent_intentTile'] .ant-select.ant-select-single .ant-select-selector .ant-select-selection-item {
      color: var(--primary-accent-color);
    }

    //sidebar
    [class*='Sidebar_leftNavigationBar'] [class*='Sidebar_liteSideBarMenu'] [class*='Sidebar_menuList'] a {
      &[class*='Sidebar_isActive'] {
        color: white;
      }
      color: #1cbdea;

      // svg {
      //   path {
      //     fill: currentColor;
      //   }
      // }
    }
    [class*='Sidebar_contactUs'] a {
      color: #1cbdea;
      // svg {
      //   path {
      //     fill: currentColor;
      //   }
      // }
    }

    //profile page
    [class*='create-user_changePasswordLink'] {
      color: var(--primary-accent-color);
    }

    .button {
      &.isPrimary {
        background-color: var(--primary-accent-color);
      }
      &.is-primary:not(.is-outlined) {
        background-color: var(--primary-accent-color);
        &:hover {
          background-color: var(--primary-theme-color);
        }
      }
      &.is-outlined,
      .ant-btn {
        border-color: var(--primary-accent-color);
        color: var(--primary-accent-color);
        &:hover,
        &:focus {
          background-color: var(--primary-theme-color);
          border-color: var(--primary-theme-color);
        }
      }
    }

    .box {
      .card-header {
        .ant-select-single {
          .ant-select-selector {
            .ant-select-selection-item {
              color: var(--primary-accent-color);
            }
          }
        }
      }
    }
  }
  .ant-modal-footer {
    .ant-btn-primary {
      background-color: #1ecfff;
      border-color: #1ecfff;
    }
    .ant-btn-primary:hover {
      background-color: var(--primary-theme-color);
      border-color: var(--primary-theme-color);
    }
  }
  .isBeta,
  .isAlpha {
    &:after {
      top: -0.5rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .cliqzContainer {
    &.amp-lite {
      .ant-tabs {
        .ant-tabs-tab {
          &.ant-tabs-tab-active .ant-tabs-tab-btn {
            font-family: Verdana, Geneva, sans-serif;
            color: var(--primary-theme-color);
            font-weight: 700;
          }
        }
      }
    }
    .ant-tabs {
      .ant-tabs-ink-bar {
        background-color: #444 !important;
      }
    }
  }
}
