@use '../../../common/scss/variables.module.scss' as *;
.container {
  background-color: #fff;
  overflow-y: hidden;
  border: 1px solid #dddddd;
  height: calc(100vh - 110px);
  .viewMetricsLabel {
    color: var(--primary-accent-color);
    cursor: pointer;
  }
}
.homeContainer {
  overflow-y: hidden;
  height: calc(100vh - 110px);
}
.accountBanner {
  height: 75px;
  background-color: #ebe9e9;
  .rowContainerFluid {
    margin-left: 20%;
    .verticalCenterAvatar {
      margin-top: 15px;
      margin-left: 8px;
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
  .accountDetails {
    padding-top: 15px;
    padding-bottom: 15px;
    .title {
      color: #000000;
      font-size: 1.3rem;
    }
    .textSize {
      font-size: 1.3rem;
    }
    .marginRight {
    }
    .marginLeft {
    }
  }
  ul {
    list-style: disc;
  }
}
.pauseContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.headerContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.actionStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.actionText {
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--primary-accent-color);
  white-space: nowrap;
}
.broadcastMsg {
  color: var(--off-black-color);
  line-height: inherit;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}
.singleUrl {
  line-height: normal;
  word-break: break-all;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  color: #1ecfff;
  .externalIcon {
    height: 20px;
    width: 20px;
    font-weight: 700;
  }
}
.plusUrls {
  background: #cccccc36;
  padding: 2px 9px 2px 9px !important;
  color: #1ecfff;
  border-radius: 7px !important;
  margin-left: 0.5%;
  font-size: 11px;
  white-space: nowrap;
}
.singleUrlWithAutoWidth {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  color: #1ecfff;
  display: block;
}
.isFlex {
  display: flex;
  &:hover {
    color: #1ecfff;
  }
}
.brodcastHeader,
.brodcastHeaderNoBorder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  padding: 1.2rem $leftRightPaddingForComponents;
  margin-top: -6px;
  margin-bottom: 7px;
  padding: 5px 0px 5px 5px;
  .brodcastHeaderTitle {
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-theme-color);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .brodcastHeaderSubTitle {
    font-size: 12px;
    color: $gray-6;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 5px 0px 0px 10px;
  }
}
.brodcastHeaderNoBorder {
  border-bottom: none;
}
.icon,
.iconTransparentBrodered {
  padding: 0.6rem;
  display: block;
  background-color: var(--primary-accent-color);
  border-radius: 5px;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.iconTransparentBrodered {
  background-color: transparent;
  border: 1px Solid rgba(14, 32, 78, 0.16);
}
.iconTransparent {
  background-color: transparent;
}
.loaderIcon {
  background-color: transparent;
  margin-right: 11px;
}
.loadingAnimation {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
.pointerCursor {
  cursor: pointer;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.broadcastCardContainer {
  height: calc(var(--real100vh) - 187px);
  overflow-y: auto;
  padding: 0 $leftRightPaddingForComponents;
}
.pageComponent {
  overflow-y: unset;
  padding: 0 $leftRightPaddingForComponents;
}
.broadcastCard {
  display: flex;
  border: 1px solid rgba(14, 32, 78, 0.16);
  padding: 1.2rem;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: #fff;
}
.broadcastContent {
  width: 100%;
  .broadcastNameContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    span {
      font-size: 1.1rem;
      color: var(--off-black-color);
      white-space: nowrap;
      font-weight: 400;
      margin-left: 5px;
    }
  }
  .contentContainer {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    width: 100%;
    & > div:first-child {
      width: inherit;
      max-width: calc(100% - 70px);
    }
  }
  .broadcastName {
    color: #001733;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 70px);
  }
  .broadcastStats {
    margin-top: 3px;
    display: flex;
    align-items: center;
    color: var(--off-black-color);
    div {
      white-space: nowrap;
      font-size: 1rem;
    }
    .vr {
      background-color: #ccc;
      margin: 0 15px;
      @media screen and (max-width: 440px) {
        margin: 0 3px;
      }
    }
  }
  .broadcastText {
    color: var(--off-black-color);
    line-height: normal;
    margin-top: 8px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
  }
}
.broadcastMedia {
  margin-left: 10px;
  .media {
    margin-left: auto;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.viewBroadcastContainer {
  border-radius: 5px;
  height: 100%;
  padding: 0 $leftRightPaddingForComponents;
  .viewHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 15px 0;
    color: $gray-4;
    border-bottom: 1px solid #dddddd;
  }
}

.landingContainer {
  height: calc(var(--real100vh) - 135px);
  overflow-y: auto;
}

.fieldContainer {
  padding: 15px 0;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .field {
    color: $gray-4;
    font-weight: 500;
  }
  .stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray-6;
    .value {
      font-weight: 500;
      margin-right: 10px;
      font-weight: 600;
    }
    .analytics {
      color: #34cbb4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 5px;
      }
      .nigative {
        color: #ff595e;
      }
      .isNa {
        color: $gray-6;
      }
    }
  }
}

.createBroadcast {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.3rem 1rem;
  background-color: var(--primary-accent-color);
  color: #fff !important;
  text-align: center;
  z-index: 1;
}

.broadcastHomeContainer {
  border: 1px solid rgba(14, 32, 78, 0.16);
  padding: 0px 15px 0px 15px;
  background-color: #fff;
}

:global {
  .broadcastMobileTabs .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    border: none;
  }
}
:global {
  .broadcastMobileTabs .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0.5rem !important;
  }
}
.metrixField,
.metrixGraph {
  display: flex;
  flex-direction: column;
}
.metrixField {
  line-height: 1.3;
  margin-top: 10px;
}
.metrixGraph {
  justify-content: center;
  align-items: center;
  div {
    width: fit-content;
  }
}

.metrixTotalFanCountField {
  line-height: 1.3;
  margin-top: 10px;
}

.broadcastMetrixMainContainer {
  margin-left: 15px;
  width: 78%;
}

.broadcastMetrixContainer {
  display: flex;
  border: 1px solid rgba(14, 32, 78, 0.16);
  padding: 2.2rem;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: #fff;
}
.broadcastMetrixTotalFanContainer {
  display: flex;
  border: 1px solid rgba(14, 32, 78, 0.16);
  padding: 2.2rem;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: #fff;
  width: 25%;
  border: 1px solid #34cbb4;
  box-shadow: 0px 0px 20.0214px rgba(0, 0, 0, 0.08);
  align-items: center;
}
.broadcastMetrixCard {
  display: flex;
  flex: auto;
  justify-content: space-between;
  min-width: 200px;
  max-width: 400px; //TODO: if the placeholder is implemented
}
.broadcastMetrixCardWithoutDirection {
  display: flex;
  flex: auto;
  justify-content: space-between;
  max-width: 400px; //TODO: if the placeholder is implemented
}

.broadcastMetrixCardstats {
  margin-top: -16px;
}
.broadcastDesktopHeader {
  border-bottom: 1px solid #dddddd;
  padding: 15px 0px 15px 0px;
  background-color: #ffffff;
  overflow-y: hidden;
}

.broadcastTabContainer {
  :global {
    .ant-tabs-nav {
      max-width: 58% !important;
      margin: auto !important;
      width: 100% !important;
    }
    .ant-tabs-content-holder {
      padding: 0 20%;
      overflow-y: auto;
      height: calc(100vh - 212px);
    }
  }
}

.desktopBrodcastHeader {
  padding: '5px 0px 5px 5px';
}
.desktopBrodcastHeaderSubTitle {
  margin: '5px 0px 0px 10px';
}

.noRecordCenter {
  height: calc(100vh - 280px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.brodcastsLoadMore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem $leftRightPaddingForComponents;
  margin-top: -6px;
  margin-bottom: 7px;
  padding: 5px 0px 5px 5px;
  flex-direction: column;
  .content {
    color: var(--primary-accent-color);
    font-weight: 400;
    font-size: 16px;
    font-family: var(--font-family-primary);
    font-style: normal;
    cursor: pointer;
  }
}
.lineChartContainer {
  :global {
    .recharts-wrapper {
      max-width: 150px !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .homeContainer {
    height: calc(100vh - 90px);
  }
  .container {
    padding: 0;
    border: none;
    height: auto;
    // height: calc(100vh - 80px);
    overflow: auto;
  }
  // .broadcastStats {
  //   justify-content: space-between;
  // }
  :global {
    .broadcastMobileTabs .ant-tabs-content-holder {
      overflow-y: auto;
      height: calc(100vh - 150px);
    }
  }
  .broadcastTabContainer {
    :global {
      .ant-tabs-nav {
        max-width: 100%;
      }
      .ant-tabs-content-holder {
        padding: 0;
        overflow-y: auto;
        height: 100%;
      }
    }
  }
  .brodcastHeader,
  .brodcastHeaderNoBorder {
    padding: 1.2rem $leftRightPaddingForComponents;
    .brodcastHeaderSubTitle {
      margin: 0px 0px 0px 0px;
    }
  }
  .broadcastMetrixTotalFanContainer {
    width: 100%;
    padding: 42px 2.2rem 2.2rem;
  }
  .broadcastMetrixMainContainer {
    margin-left: 00px;
    width: 100%;
  }
  .metrixTotalFanCountField {
    line-height: 1.3;
    margin-top: -15px;
  }
}

.broadcastDetailRow {
  border-bottom: 1px solid #dddddd;
  justify-content: start;
  align-items: center;
  padding: 10px 10px 10px 0;

  .detail-label {
    color: #999999;
    font-size: 12px;
  }
  .detail-value {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    .detail-value-seperator {
      margin: 0 5px;
    }
  }
  .detail-value.broadcast-type-value {
    font-size: 15px;
  }
  .detail-value.broadcast-name-value {
    color: #1ecfffb3;
  }

  .left-audit-detail {
    padding-right: 2rem;
    max-width: 259px;
  }
  .right-audit-detail {
    padding-left: 2rem;
    max-width: 259px;
  }
}

.viewBroadcastContainer > div:nth-last-child(1) .broadcastDetailRow {
  border-bottom: none;
}

.broadcastMobileTabs .ant-tabs-nav-list > div:nth-last-child(2) {
  margin-right: 15px;
}

@media screen and (max-width: 400px) {
  .broadcastStats {
    div {
      font-size: 0.8rem !important;
    }
    .vr {
      margin: 0 5px !important;
    }
  }
  .homeContainer {
    height: calc(100vh - 90px);
  }
  .broadcastMedia {
    margin-top: 8px;
  }
}
@media screen and (max-width: 320px) {
  .broadcastStats {
    div.vr {
      margin: 0 4px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .singleUrl {
    line-height: normal;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    color: #1ecfff;
    width: 80%;
    overflow: hidden;
  }
  .plusUrls {
    background: #cccccc36;
    padding: 2px 6px 2px 6px !important;
    color: #1ecfff;
    border-radius: 7px !important;
    margin-left: 0.5%;
    font-size: 11px;
  }
  // .broadcastMedia {
  //   margin-top: 18px;
  // }
}

@media only screen and (min-device-width: 800px) and (max-device-width: 850px) {
  .verticalCenterAvatar {
    margin-right: 0px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 800px) {
  .verticalCenterAvatar {
    margin-right: -15px !important;
  }
  .marginRight {
    margin-right: -30px;
  }
  .broadcastMedia {
    margin-top: 18px;
  }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1024px) {
  .verticalCenterAvatar {
    margin-right: 0px !important;
  }
  .marginRight {
    margin-right: -15px;
  }
  .broadcastMedia {
    margin-top: 18px;
  }
}

:global {
  .testModal {
    .phone-number {
      flex: 1;
    }
  }
}
.isLink {
  cursor: pointer;
}
.urlParts {
  display: flex;
  flex-wrap: wrap;
}
.broadcastIdText {
  text-decoration: none;
  font-size: 1.2rem;
  margin: 0px 0.5px 0px 0.5px;
  color: white;
  display: inline;
  position: relative;
}
.actBlueUrlTag {
  :global {
    .ant-tag {
      margin-right: 3px;
      width: fit-content;
      padding: 0 2px;
    }
  }
}
.viewAudit {
  margin-left: 822px;
  margin-bottom: -40px;
  margin-top: 26px;
}
.modalWrapper {
  border-radius: 6px;
  :global {
    .ant-modal {
      min-width: 45% !important;
      max-width: 900px;
    }
    .ant-modal-header {
      border-radius: 6px 6px 0 0;
      padding: 0 0 2rem 0;
      border-bottom: 1px solid #dddddd;
    }
    .ant-modal-title {
      font-weight: bold;
    }
    .ant-modal-content {
      border-radius: 6px;
      padding: 2rem 2.5rem;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
}
.viewAuditDetails {
  color: var(--primary-accent-color);
  cursor: pointer;
}
.viewAuditMobile {
  color: var(--primary-accent-color);
  cursor: pointer;
  margin: 6px;
  font-size: 12px;
}

.cloneIcon {
  font-size: 12px;
  color: #646464;
  text-align: right;
  margin-right: 0.3rem;
  margin-top: -0.4rem;
  white-space: nowrap;
  @media only screen and (max-width: 600px) {
    margin-top: 0;
  }
}

.amplifyCancel {
  border-radius: 4px;
  border: 1px solid var(--primary-accent-color);
  color: var(--primary-accent-color);
  box-shadow: none;
}
.amplifyPrimary {
  text-shadow: none;
  background-color: var(--primary-accent-color);
  border-color: var(--primary-accent-color);
  color: #fff;
  border-radius: 4px;
  &:hover {
    background-color: var(--primary-accent-color);
    opacity: 0.9;
  }
}
