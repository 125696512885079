@use '../../../common/scss/variables.module.scss' as *;
.container {
  display: flex;
  align-items: center;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  font: normal normal 800 4rem/4rem var(--font-family-primary);
  letter-spacing: 1.11px;
  color: #00304a;
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.subtitle {
  font: normal normal normal 1.4rem/1.8rem var(--font-family-primary);
  letter-spacing: 0px;
  color: #444444;
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.listContainer {
  height: 35vh;
  overflow-y: auto;
  padding: 0 1rem 0.5rem 1rem;
}
.fbPageContainer {
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 6px;
  margin-top: 1.2rem;
  :global {
    .ant-checkbox-wrapper {
      width: 100%;
    }
    .ant-checkbox {
      margin-left: 2rem;
    }
    .ant-checkbox + span {
      padding: 0;
    }
  }
}
.selectedFbPageContainer {
  composes: fbPageContainer;
  background-color: #f2f8fc;
}
.instaPageContainer {
  position: relative;
}
.connectedLine {
  border: none;
  border-bottom: 1px dashed #1294dc;
  border-left: 1px dashed #1294dc;
  background-color: #f5f5f5;
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 0 2.5rem 3rem;
  position: absolute;
}
.instaPageContent {
  composes: fbPageContainer;
  margin-left: 6rem;
}
.selectedInstaPageContent {
  composes: instaPageContent;
  background-color: #f2f8fc;
}
.fbPagePic {
  width: 30px;
  height: 30px;
  margin: 1rem 2rem;
  border-radius: 3rem;
  user-select: none;
}
.fbPageName {
  user-select: none;
  font: normal normal normal 1.4rem/1.8rem var(--font-family-primary);
  letter-spacing: 0px;
  color: #666666;
}
.deployButton {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font: normal normal 600 1.6rem/2rem var(--font-family-primary);
  margin-left: 1rem;
  margin-right: 1rem;
}
.returnToLandingContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.backToFb {
  composes: returnToLandingContainer;
  margin-bottom: 0.25rem;
}
.returnToLanding {
  font: normal normal normal 1.4rem/1.6rem var(--font-family-primary);
  letter-spacing: 0px;
  color: #1297e0;
  cursor: pointer;
}

.noPagesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  > span {
    font: normal normal normal 1.2rem/1.6rem var(--font-family-primary);
    color: $gray-4;
    padding-top: 1rem;
    user-select: none;
    letter-spacing: 0px;
  }
}
.search {
  padding: 0 1rem;
  > input {
    font: normal normal normal 1.4rem/2rem var(--font-family-primary);
    border-radius: 0;
  }
}
// For Mobile
@media screen and (max-width: 1400px) {
  .listContainer {
    height: 35vh;
  }
}
@media screen and (max-width: 600px) {
  .content {
    background-color: white;
    width: 100%;
    padding-top: 40px;
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 0px;
  }
  .subtitle {
    text-align: center;
  }
  .title {
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }
  .text {
    color: #444444;
    /* padding-bottom: 56px; */
    line-height: 46px;
  }
  .noPagesContainer {
    > img {
      padding-bottom: 00px;
      margin-left: 00px;
      width: auto;
    }
  }

  .deployButton {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .listContainer {
    height: 28vh;
  }
  .fbPagePic {
    padding-bottom: 0px;
  }
}

// For Tablet
@media screen and (width: 768px) {
  .content {
    background-color: white;
    width: 100%;
    padding-top: 00px;
    padding-left: 00px;
    margin-top: 00px;
    padding: 80px 13rem 0rem 13rem;
  }
  .title {
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin-left: 15px;
  }
  .text {
    color: #444444;
    /* padding-bottom: 56px; */
    line-height: 46px;
  }
  .noPagesContainer {
    > img {
      padding-bottom: 00px;
      margin-left: 00px;
      width: auto;
    }
  }

  .deployButton {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .listContainer {
    height: 28vh;
  }
  .fbPagePic {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 375px) {
  .content {
    width: 100%;
    padding-top: 0px;
  }
}

.botTemplate {
  padding: 0 1rem;
  label {
    margin-right: 5px;
  }
  :global {
    .ant-divider-horizontal {
      margin: 8px 0;
    }
  }
}
