@use '../../../common/scss/variables.module.scss' as *;

.tooltip {
  :global(.ant-tooltip-arrow) {
    overflow: visible;
  }

  :global(.ant-tooltip-arrow-content) {
    border-bottom: 1px solid #bae7ff;
    border-right: 1px solid #bae7ff;
  }

  :global(.ant-tooltip-inner) {
    color: $gray-4;
    font: normal normal normal 1.4rem/1.9rem var(--font-family-primary);
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #bae7ff;
    border-radius: 6px;
  }
}

.tooltipDanger {
  :global(.ant-tooltip-arrow) {
    overflow: visible;
  }

  :global(.ant-tooltip-arrow-content) {
    border-bottom: 1px solid red;
    border-right: 1px solid red;
  }

  :global(.ant-tooltip-inner) {
    color: $gray-4;
    font: normal normal normal 1.4rem/1.9rem var(--font-family-primary);
    box-shadow: 0px 3px 6px #38363629;
    border: 1px solid red;
    border-radius: 6px;
  }
}
