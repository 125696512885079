// @import 'react-image-crop/src/ReactCrop.scss';
@use './common/scss/variables.module.scss' as *;
@use './common/scss/utility' as *;
@use './common/scss/triller-global.scss' as *;
@use './common/scss/cliqz-global.scss' as *;
@use './common/scss/julius-global.scss' as *;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
//TODO preload fonts do not download

@import 'node_modules/react-image-crop/dist/ReactCrop';

@import '~antd/dist/antd.css';

$primary: $primary-accent-color;
$family-primary: $primary-font;
@import 'bulma/bulma';
// @import './mobile/mobile.scss';

html {
  font-size: 10px;
  overflow-y: auto;
}

body {
  font-family: $primary-font;
  .ant-input[disabled] {
    background-color: whitesmoke;
    border-color: whitesmoke;
    box-shadow: none;
    color: #000000bf;
  }
  .input[disabled],
  .textarea[disabled],
  .select select[disabled],
  fieldset[disabled] .input,
  fieldset[disabled] .textarea,
  fieldset[disabled] .select select,
  .select fieldset[disabled] select {
    color: #000000bf;
    background-color: whitesmoke;
    border-color: whitesmoke;
    box-shadow: none;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-radio-disabled + span {
    color: #000000bf;
  }
}

.ant-scrolling-effect {
  .lite-header {
    max-width: calc(100% - var(--scrollbar-width));
  }
}

.amp-lite {
  background-color: $gray-f5;
  min-height: 100vh;

  font-size: 1.4rem;
  font-family: var(--font-family-primary);
  .notification.is-warning.is-light {
    color: #dc9700;
    background-color: #fcb72026;
  }

  .notification {
    padding: 1rem 2.5rem 1rem 1.5rem;
  }
}
.lite-main-content {
  margin-left: 190px;
  /* Same as the width of the sidenav */
  padding: 2rem;
  padding-top: calc(70px + 2rem);

  &.no-sidebar {
    margin: auto;
  }
  &.collapse-sidebar {
    margin-left: 55px;
  }
}

.amp-lite.useAsIframe {
  .lite-main-content {
    margin: auto;
    padding-top: 2rem;
  }

  .AmpLiteleftNav {
    display: none;
  }

  .lite-header {
    display: none;
  }

  [class*='common-components_helpTextIcon'] {
    display: none;
  }

  [class*='page-container_container'] {
    height: calc(100vh - 4rem);
  }

  [class*='infinite-scroll-container'] {
    height: calc(100vh - 4rem - 16rem);
  }

  [class*='message-details_templatesList'] {
    width: calc(58vw - 20px);
  }
}

.search-heighlight {
  background-color: var(--primary-theme-color);
  color: #ffffff;
}

.position-relative {
  position: relative;
}

// For Mobile
@media screen and (max-width: 600px) {
  .lite-main-content {
    position: absolute;
    width: 100%;
    padding-top: 0;
    padding: 0;
    margin-left: 0px;
    margin-top: 234px;

    &.no-sidebar {
      margin-top: 180px;
    }

    background-color: #ffffff;
  }

  .amp-lite {
    background-color: #ffffff;
  }
}

// For Tablet
@media screen and (width: 768px) {
  .lite-main-content {
    position: absolute;
    width: 100%;
    padding: 0;
    margin-left: 0px;
    margin-top: 335px;

    &.no-sidebar {
      margin-top: 335px;
    }

    background-color: #ffffff;
  }

  .amp-lite {
    background-color: #ffffff;
  }
}
@media screen and (max-width: 599px) {
  .lite-main-content:not(.no-sidebar) {
    position: relative;
    width: 100%;
    margin-top: 0;
    padding: 10px 10px 0 10px;
    margin-left: 0px;
    padding-top: 70px;
    background-color: #ffffff;
    height: calc(var(--real100vh));
  }
  .amp-lite {
    min-height: var(--real100vh);
  }
}

span.anticon.anticon-close.diplayNone {
  display: none;
}
.ReactCrop__child-wrapper > img {
  width: 100% !important;
}
.ReactCrop {
  width: 100% !important;
}

.app-container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  height: fit-content;
  width: fit-content;
  background-size: cover;
  background-position: center; // Center the image
  color: white; // Change text color for better visibility
}

@font-face {
  font-family: 'TT-Commons-Light';
  src: url('../src/assets/fonts/TTCommons-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT-Commons';
  src: url('../src/assets/fonts/TTCommons-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
