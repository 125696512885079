@use '../../common/scss/variables.module.scss' as *;
.row {
  display: flex;
  flex-direction: row;
}

$gray-f1: #f1f1f1;

.analysisContainer {
  overflow-y: auto;
  height: 100%;
  padding: 10px 0px 20px 0px;
  .noDataContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    .noDataElement {
      font-size: 25px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .analysisHeader {
    margin: 10px 0px 20px 0px;
  }
  .smiley {
    color: $sentiment-positive;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    margin-left: 20px;
    img {
      margin-right: 5px;
      margin-top: 5px;
    }
  }
  .analysisFilterTag {
    color: var(--primary-accent-color);
    border-radius: 50px;
    border: 1px dotted var(--primary-accent-color);
    margin: 2px 3px 2px 3px;
    padding: 2px 5px 0px 10px;
    display: flex;
    flex-direction: row;
    height: 27px;
    svg {
      width: 20px;
      height: 17px;
      margin-top: 2px;
    }
  }
  .analysisFilterExtraTag {
    padding-right: 15px;
    color: white;
    border-style: solid;
  }
  .clearAnalysisFilter {
    margin-left: 5px;
    margin-bottom: 5px;
    min-width: 100px;
    .clearAnalysisFilterLink {
      color: $gray-9;
      font-size: 12px;
      text-decoration: underline;
      svg {
        width: 17px;
        height: 17px;
      }
    }
  }
  .hdivider {
    height: 2px;
    width: 100%;
    background-color: $gray-f1;
    margin: 10px 0px;
  }
  .vdivider {
    height: auto;
    width: 2px;
    background-color: $gray-f1;
    margin: 0px 10px;
  }
  .analysisChartContainer {
    &.dateChartContainer {
      flex: 1 1 0;
    }
    width: 48%;
    min-width: 400px;
    padding: 10px 20px;
    .hdivider {
      height: 1px;
    }
    border: 0.5px solid $gray-f1;
    :global {
      label.ant-checkbox-wrapper {
        margin: 0px 10px;
      }
      .fieldName {
        font-size: 12px;
        position: relative;
        top: 2px;
      }
    }
  }
  .subscriptionDatePicker {
    border-radius: 5px;
    min-width: 150px;
    margin: 0px 5px;
  }
  .subscriptionDatePickerDisabled {
    background: white;
    cursor: pointer;
  }
  :global {
    .daterangepicker.ant-picker-focused input {
      color: var(--primary-accent-color);
    }
  }
  .chartAxisLabelLeft {
    margin-top: 130px;
    line-height: 40px;
    width: 100px;
    margin-right: 20px;
    color: $gray-4;
  }
  .chartAxisLabelRight {
    // margin-top: 111px;
    line-height: 1.4;
    // width: 75px;
    margin-top: 0;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-left: 20px;
    // margin-right: 10px;
    color: $gray-4;
    b {
      font-size: 12px;
    }
    div {
      font-size: 23px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .chartRightSubHeading {
    font-size: 18px !important;
  }
  .analysisFilterSection {
    background-color: $gray-f5;
    margin: 10px 0px;
    padding: 5px 15px 5px 20px;
    height: 55px;
    border-radius: 5px;
  }
  .analysisFilterCombo {
    margin: 5px;
    color: var(--primary-accent-color);
    width: 150px;
    :global {
      .ant-select-selector {
        border-color: var(--primary-accent-color);
        border-radius: 5px;
        height: 35px;
        background-color: $gray-f5;
      }
      .ant-select-selection-item {
        border-width: 0px;
        margin-left: -2px;
        margin-bottom: 0px;
      }
      .ant-select-selection-placeholder,
      .ant-select-arrow,
      .ant-select-selection-item {
        color: var(--primary-accent-color);
      }
    }
  }
  .analysisChartTitle {
    svg {
      width: 15px;
      height: 15px;
      margin: 2px 0px 0px 5px;
    }
  }
}

.analysisFilterDropdown {
  width: 300px;
  max-height: 400px;
  padding: 5px;
  :global {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--primary-accent-color);
      border-color: var(--primary-accent-color);
    }
  }
  .analysisFilterHeader {
    height: 50px;
    padding: 10px;
  }
  .isOptionsLoading {
    width: 100%;
    height: 200px;
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      height: 200px;
      width: 100%;
      background-color: white;
    }
  }
  .customAgeTitle {
    margin: 0px 10px;
    color: $gray-4;
  }
  .ageFilterTag {
    border-radius: 16px;
    font: normal normal bold 1rem/1.4rem var(--font-family-primary);
    color: $gray-6;
    padding: 0.5rem 1rem;
    margin: 5px;
    background-color: rgb(241, 241, 241);
  }
  .ageBox {
    margin: 5px;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .analysisFilterList {
    height: 200px;
    border: 1px solid $gray-f1;
    border-width: 1px 0px;
    margin: 5px;
    .analysisFilterItem {
      padding: 5px;
      height: 32px;
      display: flex;
      flex-direction: row;
      color: $gray-6;
    }
  }
  .analysisFilterSelectionTitle {
    margin: 0px 10px;
    color: $gray-4;
  }
  .analysisFilterSelectionList {
    min-height: 40px;
    max-height: 70px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    .analysisFilterSelectedOption {
      color: var(--primary-accent-color);
      border-radius: 50px;
      border: 1px dotted var(--primary-accent-color);
      margin: 2px 3px 2px 3px;
      padding: 2px 5px 0px 10px;
      display: flex;
      flex-direction: row;
      height: 27px;
      span {
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        width: 20px;
        height: 17px;
        margin-top: 2px;
      }
    }
    .analysisFilterExtraTag {
      padding-right: 15px;
      color: white;
      border-style: solid;
    }
  }
  .analysisFilterFooter {
    height: 50px;
    border-top: 1px solid $gray-f1;
    display: flex;
    flex-direction: row-reverse;
    .analysisFilterDropdownButton {
      margin: 10px;
      border-radius: 5px;
    }
  }
}

.exportCsv {
  margin: 0px 5px -2px 5px;
  cursor: pointer;
}

.analysisPercentDataModal {
  .analysisPercentDataTable {
    height: 310px;
    margin: -15px 0px 15px 0px;
    .percentDataRow,
    .percentDataTitleRow {
      display: flex;
      height: 30px;
      flex-direction: row;
      .percentDataLabelCell {
        flex: 2 1 0;
      }
      .percentDataPercentCell {
        flex: 1 1 0;
      }
    }
    .percentDataTitleRow {
      margin-bottom: 10px;
      border-bottom: 2px solid $gray-f1;
    }
  }
}

.customPieLegend {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 200px;
  transform: translateX(225px);
  width: 120px;
  .customPieLegendItem {
    font-size: 15px;
    margin: 5px 10px 5px 10px;
    .customPieLegendIcon {
      border-radius: 100%;
      height: 10px;
      min-width: 10px;
      margin: 5px;
    }
    .customPieLegendTitle {
      display: flex;
      flex-direction: row;
      font-size: 12px;
    }
    .customPieLegendPercent {
      margin-left: 20px;
      font-size: 12px;
      opacity: 0.5;
    }
  }
}

.switchUnavailable {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  font-size: 12px;
  color: $gray-4;
  font-family: var(--primary-font-family);
  cursor: pointer;
  padding-right: 20px;
  .switchUnavailableToggle {
    margin: 2px 5px;
  }
}

.analysisHeaderFans {
  font-family: var(--primary-font-family);
  color: $gray-4;
  div {
    font-size: 27px;
  }
  span {
    font-size: 22px;
  }
  b {
    font-size: 16px;
  }
}

.analysisActionButton,
.analysisActionButton:hover,
.analysisActionButton:focus {
  background-color: var(--primary-accent-color);
  color: $white;
  margin-left: 10px;
  margin-right: 0px;
  border-radius: 5px;
}

.fansByFieldModal {
  border-radius: 5px;
  :global {
    .ant-modal-header,
    .ant-modal-close-icon,
    .ant-modal-title {
      background-color: var(--primary-accent-color);
      color: white;
      border-radius: 10px 10px 0px 0px;
    }
    .ant-modal-content {
      border-radius: 10px;
    }
  }
}

.customTooltip {
  display: flex;
  flex-direction: column;
  color: $gray-4;
  b {
    margin: -5px auto 5px auto;
  }
}
.horizontallyAligned {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 120px !important;
}
.customDateTooltip {
  border: 1.5px solid $gray-f1;
  border-radius: 5px;
  min-width: 180px;
  box-shadow: 0px 0px 1px 1px $gray-f1;
  background-color: $white;
  padding: 10px 20px 10px 20px;
  position: relative;
  .customDateTooltipLabel {
    font-family: var(--primary-font-family);
    font-size: 14px;
    color: $gray-4;
  }
  .customDateTooltipListItem {
    display: flex;
    flex-direction: row;
    color: $gray-4;
    font-family: var(--primary-font-family);
    font-size: 13px;
    margin-right: 10px;
    padding-right: 1rem;
    .customDateTooltipIcon {
      width: 12px;
      height: 12px;
      border-radius: 10px;
      margin: 4px 5px 5px 0px;
    }
    .customDateFill {
      flex: 1 1 0;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  padding-left: 10px;
  .exportUserButton {
    display: block;
    color: $primary-accent-color;
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-weight: 600;
    cursor: pointer;
  }
}

$chart-colors: (
  chartAxisColor: #ced4e5,
  missingDataColor: #f4e7db,
  chartRed: #fa6956,
  chartOrange: #f0b43c,
  chartTeal: #36bfc9,
  chartBlue: #5fbae4,
  chartGreen: #adcb62,
  chartIndigo: #9078c7,
  chartYellow: #f0d3a7,
  chartPink: #ea9a9c,
  successGreen: $sucess-green,
  warningYellow: $warning-yellow,
  tomatoRed: $tomato-red,
);

:export {
  gray4: $gray-4;
  grayF1: $gray-f1;
  @each $key, $value in $chart-colors {
    #{unquote($key)}: $value;
  }
}
