@use '../../../common/scss/variables.module.scss' as *;

.container {
  :global(.ant-popover-inner) {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #bae7ff;
    border-radius: 6px;
  }
  :global(.ant-popover-message-title) {
    padding-left: 0;
    user-select: none;
  }
  &:global(.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow) {
    border-bottom-color: white;
    border-right-color: white;
    border-top-color: #bae7ff;
    border-left-color: #bae7ff;
  }
  :global(.ant-popover-content > .ant-popover-arrow) {
    right: 20px;
    background-color: white;
    border-bottom-color: #bae7ff;
    border-right-color: #bae7ff;
    border-width: 1.75px;
  }
  :global(.ant-popover-buttons) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  :global(.ant-btn) {
    background-color: transparent;
    border: 1px solid var(--primary-accent-color);
    color: var(--primary-accent-color);
    border-radius: 4px;
    box-shadow: none;
    transition: none;
    padding: 0.25rem 1rem;
    text-align: center;
    white-space: nowrap;
    height: unset;
    > span {
      font-weight: bold;
    }
  }
  :global(.ant-btn:hover) {
    border-color: #118fd4;
    color: #118fd4;
    background-color: white;
  }
  :global(.ant-btn-primary) {
    text-shadow: none;
    background-color: var(--primary-accent-color);
    border-color: var(--primary-accent-color);
    color: #fff;
  }
  :global(.ant-btn-primary:hover) {
    background-color: #118fd4;
    border-color: #118fd4;
    color: #fff;
  }

  :global(.ant-btn:last-child) {
    margin-left: 0;
  }
}
